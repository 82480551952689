import React from 'react'
import './LoaderScr.css'

const LoaderScr = ({ isLoading }) => {
  return (
    <>    {isLoading ?
      <div className="my-component">
        <div className="my-component-loader-overlay">
          <div className="my-component-loader"></div>
        </div>
      </div>
      : null}
    </>


  )
}

export default LoaderScr