import React from 'react'
import { useLocation,useNavigate } from 'react-router-dom';
import konsole from '../network/konsole';
import { Constant } from '../utilities/Constant';
import { Button } from 'react-bootstrap';

const QuizResult = () => {
  const location = useLocation();
  const navigate=useNavigate()
  const stateData = location.state;
  let imagevalue=stateData?.imagevalue
  let textvalue=stateData?.textvalue
  let emaillegal=stateData?.emaillegal
  let homename=stateData?.homename
  
  konsole.log("")
 return (
    <div className="container mt-5  mb-5">
      <div className='container p-5'>
        <>
          <h2>Your Results:{imagevalue}</h2>
          <p>{textvalue}</p>
          <p>{emaillegal}</p>
        </>
        <p className="mt-2 justify-content-between">
        </p>
   </div>
      <div className='p-5'>
            <button type="button" className="btn btn-info pt-2 ps-3 pb-2 text-light" 
            onClick={()=>navigate(-1)}>Retake Quiz</button>
      </div>
   
      <footer className='text-center mt-5 fw-bold'>© Copyright AgingOptions. All Rights Reserved</footer>
    </div>
  )
}
export default QuizResult
