import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { utilities } from '../utilities/utilities';
import { leadGetApi ,leadCommonApi} from '../network/Services';
import Services from '../network/Services';
import konsole from '../network/konsole';
import LoaderScr from '../components/LoaderScr';

function UserInformation3() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userPhone, setUserPhone] = useState('')
    const [userPhoneError, setUserPhoneError] = useState("");

    const handleClick = (e) => {
        e.preventDefault();
        if (userPhoneError == undefined || userPhoneError == null || userPhoneError == "") {
            setIsLoading(true)
            let result = Services.getUserDetailsByEmailId(userEmail)
            result.then((res) => {
                setIsLoading(false)
                konsole.log("getUserDetailsByEmailId", res)
                let responseData = res?.data
                registrationInLeadStackers()
                if (responseData.length > 0) {
                   
                    navigateRoute(responseData[0]?.userId)
                } else {
                    setIsLoading(false)
                    createUserApi()
                    
                }
            }).catch((err) => {
                konsole.log("getUserDetailsByEmailId", err)
                setIsLoading(false)
            })
        }
    }


    const createUserApi = () => {
        let splitUserName = userName.split(' ')
        let firstName = splitUserName[0]
        let lastName = splitUserName?.slice(1).join(' ')
        let jsonobj = {
            "subtenantId": 3,
            "signUpPlateform": 12,
            "user": {
                "roleId": 19,
                "firstName": firstName,
                "lastName": (lastName) ? lastName : firstName,
                "emailAddress": userEmail,
                "userName": userEmail,
                "countryCode": "+1",
                "mobileNumber": userPhone,
            }
        }
        konsole.log("jsonObject", JSON.stringify(jsonobj))
        setIsLoading(true)
        // let result = Services.createNewUser(jsonobj)
        let result = Services.createAcademyUser(jsonobj)
        result.then((res) => {
            setIsLoading(false)
            konsole.log("createNewUser", res)
            let responseData = res.data.data
            navigateRoute(responseData?.userId)
        }).catch((err) => {
            setIsLoading(false)
            konsole.log("createNewUser", err)
            if (err.response.data.data?.userId !== undefined && err.response.data.data?.userActivationMode === "ACTIVATE_USER") {
                navigateRoute(err.response.data.data.userId);
            }
        }).finally(() => {
            setIsLoading(false)
            konsole.log("createNewUser")
        })
    }

    //navigatewithprimaryuserId-------------------------------------------------------
    const navigateRoute = (userId) => {
        let jsonobj = { userEmail: userEmail, userMobile: userPhone, userName: userName }
        utilities.setSessionStoarge("userDetails", JSON.stringify(jsonobj));
        utilities.setSessionStoarge("primaryUserId", userId);
        utilities.setSessionStoarge("subtenantId", 3);

        navigate("legal");
    }
    const registrationInLeadStackers = async()=>{
        let tags = [`took free legal assessment`]
        let parts = userName.split(" ")
         let json2 ={
            "firstName":parts[0],
            "lastName":parts.slice(1).join(" "),
            "email":userEmail,
            "phone":userPhone

         }
        const result2 = await getLoggedInUserdata(userEmail,"POST",tags,json2);

    }
    const getLoggedInUserdata = async (email,method,tag,json2) => {
  
       
        let json = {
          email : email,
          tag : tag
     }
        const result = await leadGetApi(json,json2);
        if(result !== null && result !== undefined && result !== "" && !result?.tags.some((ele)=> ele === "took free legal assessment")){
          const result2 = await leadCommonApi(result,method,tag)
           return result2
        }
      if(result=='err') return;
      }


    const capitalization = (e) => {
        const capitalized = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
        console.log('capitalized', capitalized)
        setUserName(capitalized)

    }
    const mobileChange = (e) => {
        setUserPhone(utilities?.formatUsPhoneNumber(e.target.value))
        setUserPhoneError("")
    }
    const handelSend = (e) => {
        if (e.target.value.length < 14 && e.target.value.length > 0) {
            setUserPhoneError("Please enter a  valid cell number");
            return;
        }

    }

    return (
        <div className='container'>
            <LoaderScr isLoading={isLoading} />
            <Form className='' onSubmit={handleClick}>
                <header className='form-header my-5 d-flex gap-1 flex-column'>
                    <h4>Are your legal documents adequately prepared?</h4>
                    <h4>Take the Legal Quiz and Find Out.</h4>
                </header>
                <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Name" required onChange={(e) => capitalization(e)} style={{ textTransform: "capitalize" }} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail" >
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Email" required onChange={(e) => setUserEmail(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="text" placeholder="Phone" value={userPhone} onChange={(e) => mobileChange(e)} onBlur={(e) => handelSend(e)} />
                    <p className='text-danger mb-0'>{userPhoneError && <div className="mb-0">{userPhoneError}
                    </div>}</p>
                </Form.Group>
                <Button
                    variant="primary" type="submit"
                >
                    Next
                </Button>
                <footer className='text-center mt-5'>© Copyright AgingOptions. All Rights Reserved</footer>
            </Form>
        </div>
    )

}

export default UserInformation3