import  Health  from "./Health";
import { useEffect, useState,useRef } from "react"
import ProgressBar  from "react-bootstrap/ProgressBar"
import Button from 'react-bootstrap/Button';

export function Healthindex()
{
    const now = 60;
    const [ progesBar, setProgessBar ] = useState(25);
    const homestructureRef=useRef(null)
    function handleClick (event)
   {
    const eventName = event.target.name;
    let progesBarLabel = progesBar;
    switch(eventName){
        case "nextButton": 
        if(progesBarLabel == 0){
            setProgessBar(25);
        }
        else if(progesBarLabel == 25){
            setProgessBar(50)
        }
        else if(progesBarLabel == 50){
          setProgessBar(75)
      }
        // else if (progesBarLabel == 75) {
        //     setProgessBar(100)
        // }
        break;
        case "previousButton":
            // if (progesBarLabel == 100) {
            //     setProgessBar(75);
            // }
            // else
            if (progesBarLabel == 75) {
                setProgessBar(50)
            }
            else if (progesBarLabel == 50) {
                setProgessBar(25)
            }
            break;
        case "submitButton":
            homestructureRef.current.savePostData2()

    }
  }
    return(
        <>
            <Health progesBar={progesBar} ref={homestructureRef}></Health>
            <div className="container mt-3 ">
        <div className="d-flex align-items-center gap-2 my-3">
              {(progesBar !== 0 && progesBar !==25 ) ? <Button name="previousButton" variant="primary" onClick={handleClick}>Previous</Button> : <></> } 
              <ProgressBar now={progesBar} label={`${progesBar}%`} className="flex-grow-1"/>
              {(progesBar !== 75) ? <Button name="nextButton" variant="primary" onClick={handleClick}>Next</Button> : 
                <Button name="submitButton" variant="primary" onClick={handleClick}>Submit</Button>} 
                
        </div>
        <footer className='text-center mt-5'>© Copyright AgingOptions. All Rights Reserved</footer>
    </div>
            
            

        </>
    )
}