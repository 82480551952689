import React from 'react'
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import UserInformation from "./pages/userInformation";
import HomeStructure from "./pages/HomeStructure ";
import './App.css'
import Index from "./pages";
import QuizResult from "./pages/QuizResult";
import LoaderScr from './components/LoaderScr';
import Login from './pages/Login';
import { Healthindex } from './pages/Healthindex';
import { Legalindex } from './pages/Legalindex';
import UserInformation2 from './pages/userInformation2';
import UserInformation3 from './pages/useInformation3';


function App() {

  const router = createBrowserRouter([
    {
      path: "user-information/home-fitness-quiz",
      element: (<UserInformation />),
      errorElement: (<div>Dang!</div>),
    }
    ,
     {
      path: "user-information/health-fitness-quiz",
      element: (<UserInformation2 />),
      errorElement: (<div>Dang!</div>),
    }
    ,
    {
      path: "user-information/legal-fitness-quiz",
      element: (<UserInformation3 />),
      errorElement: (<div>Dang!</div>),
    }
    ,
    {
      path: "user-information/home-fitness-quiz/home",
      element: (<Index />),
      errorElement: (<div>Dang!</div>),
    }
    ,
    {
      path: "user-information/health-fitness-quiz/health",
      element: (<Healthindex />),
      errorElement: (<div>Dang!</div>),
    },
    {
      path: "user-information/legal-fitness-quiz/legal",
      element: (<Legalindex />),
      errorElement: (<div>Dang!</div>),
    },
    {
      path: "user-information/fitness-quiz-result",
      element: (<QuizResult />),
      errorElement: (<div>Dang!</div>),
    },{
      path: "/",
      element: (<Login />),
      errorElement: (<div>Dang!</div>),
    }
    
  ],{
    basename: '/'
  })

  return (
      <RouterProvider router={router} fallbackElement={<div>Dang!</div>}/>
  );
}

export default App;
