
import { useEffect, useState,useRef } from "react"
import ProgressBar  from "react-bootstrap/ProgressBar"
import Button from 'react-bootstrap/Button';
import Legal from "./Legal";

export function Legalindex()
{
    const now = 60;
    const [ progesBar, setProgessBar ] = useState(0);
    const homestructureRef=useRef(null)
    function handleClick (event)
   {
    const eventName = event.target.name;
    let progesBarLabel = progesBar;
    switch(eventName){
        case "nextButton": 
        if(progesBarLabel == 0){
            setProgessBar(50);
        }
        else if(progesBarLabel == 50){
            setProgessBar(100)
        }
        // else if (progesBarLabel == 75) {
        //     setProgessBar(100)
        // }
        break;
        case "previousButton":
            // if (progesBarLabel == 100) {
            //     setProgessBar(75);
            // }
            // else
             if (progesBarLabel == 100) {
                setProgessBar(50)
            }
            else if (progesBarLabel == 50) {
                setProgessBar(0)
            }
            break;
        case "submitButton":
            homestructureRef.current.savePostData3()

    }
  }
    return(
        <>
            <Legal progesBar={progesBar} ref={homestructureRef}></Legal>
            <div className="container mt-3 ">
        <div className="d-flex align-items-center gap-2 my-3">          
                <Button name="submitButton" variant="primary" onClick={handleClick}>Submit</Button>              
        </div>
        <footer className='text-center mt-5'>© Copyright AgingOptions. All Rights Reserved</footer>
    </div>
            
            

        </>
    )
}