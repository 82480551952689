import axios from 'axios';
import konsole from "./konsole";
import urlPath from './url';
import AoUrl, { BASE_URL,LeadStackerBASE_URL } from './url';



axios.defaults.timeout = 15000;
axios.defaults.baseURL = BASE_URL;
axios.defaults.headers['Content-Type'] = 'application/json; charset=utf-8'
const invoke = (url, method, body, cancel) => {

    if(cancel){
        cancel();
    }

    try {
        let token = sessionStorage.getItem("AuthToken");

        konsole.log("token ", `Bearer ${token}`);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        konsole.log('URL: '+url);
        konsole.log('method:'+method);
        konsole.log(((method === "POST")?"body": 'params')+JSON.stringify(body));

        return axios({
            cancelToken:new axios.CancelToken(function executor(c) {
                cancel = c;
            }),
            method:method,
            url:url,
            params:(method==="GET")?body:null,
            data:(method==="POST" || method === "PUT")?body:null
        });
    }catch(error){
        if(axios.isCancel(error)) {
            konsole.error('Request canceled', error.message);
        } else {
            konsole.error('Something went wrong: ', error.message)
        }
    }
};
const invokes = (url, method, body, cancel) => {

    if(cancel){
        cancel();
    }

    try {
 
        const LeadStackerToken1 = "6606a0c3-2cbf-4a3a-b12d-484c15559f61";//AO
        
        axios.defaults.headers.common['Authorization'] = `Bearer ${LeadStackerToken1}`;

        return axios({
            cancelToken:new axios.CancelToken(function executor(c) {
                cancel = c;
            }),
            method:method,
            url:url,
            params:(method==="GET")?body:null,
            data:(method==="POST" || method === "DELETE" || method === "PUT")?body:null
        });
    }catch(error){
        if(axios.isCancel(error)) {
            konsole.error('Request canceled', error.message);
        } else {
            konsole.error('Something went wrong: ', error.message)
        }
    }
};

const Services = {
    getSubjectQuestionsServices: (bodyJson) => {    
        let cancel;
        let url = urlPath.getSubjectQuestionsPath;
        let body = bodyJson;
        return invoke(url, "POST", body, cancel);
    },
    getUserDetailsByEmailId:async(emailId)=>{
        let cancel;
        let url=urlPath.getUserDetailsByEmailId+`?emailId=${emailId}`
        let body={}
        return invoke(url,"GET",body,cancel);
    },
    createNewUser:async(dataObj)=>{
        let cancel;
        let url=urlPath.createUser
        return invoke(url,"POST",dataObj,cancel);
    },
    createAcademyUser:async(dataObj)=>{
        let cancel;
        let url=urlPath.createAcademyUser
        return invoke(url,"POST",dataObj,cancel)
    },
    getSubjectQuestions: async (id) => {
        let cancel;
        let url = urlPath.getSubjectQuestionsPath;
        return invoke(url, "POST", id, cancel);
      },
      getSubjectResponse: async (userId, questionId) => {
        konsole.log("userud",userId,questionId)
        let cancel;
        let url = urlPath.getSubjectResponsePath + userId + `/0/0/${questionId}`;
        return invoke(url, "GET", "", cancel);
      },
      putSubjectResponse:async(dataObj)=>{
        let cancel;
        let url=urlPath.putSubjectResponse
        return invoke(url, "PUT", dataObj, cancel);
      },
      getCommMediumPath:async(bodyData)=>{
        let cancel;
        let url= urlPath.getPostCommMediumPath
        return invoke(url,"POST",bodyData,cancel)
    },
    getTextTemplate:async(TempalteId,isActive)=>{
        let cancel;
        let url= urlPath.getTextTemplate + `?TextTemplateId=${TempalteId}&IsActive=${isActive}`
        let body={}
        return invoke(url,"GET",body,cancel)
    },
    getEmailTemplate:async(TempalteId,isActive)=>{
        let cancel;
        let url=urlPath.GetEmailTemplate +`?TemplateId=${TempalteId}&IsActive=${isActive}`
        let body={}
        return invoke(url,"GET",body,cancel)
    },
    PostEmailCom:async(data)=>{
        let cancel;
        let url=urlPath.PostEmailCom
        return invoke(url,"POST",data,cancel)
    },
    getSubjectQuizScore:async(userId,topicId)=>{
        let cancel;
        let url=urlPath.getSubjectQuizScore+userId+'/'+topicId
        let body={}
        return invoke(url,"GET",body,cancel)
    },
    ////////////////   leadstackers flow data
    getContactLead: async (email) => {
        let cancel;
        let url = `${LeadStackerBASE_URL}/${AoUrl.getLeadstackerContacts}?email=${email}`
        let body = {};
        return invokes(url, "GET", body, cancel);
    },
    postContactLead: async (json,tag) => {
        let cancel;
        let url = LeadStackerBASE_URL + "/" +AoUrl.postLeadstackerContacts + json?.id + "/tags/"
        let body = {};
        body['tags'] = tag;

        return invokes(url, "POST", body, cancel);
    },
    postContactLeadRegist: async (json,tag) => {
        let cancel;
        let url = LeadStackerBASE_URL+"/"+AoUrl.postLeadstackerContacts
        let body1 = {}    
        ;
        body1['email'] = json.email;
        body1['phone'] = json.phone;
        body1['firstName'] = json.firstName;
        body1['lastName'] = json.lastName;
        body1['tags'] = tag;

        return invokes(url, "POST", body1, cancel);
    },

}
export const leadPostApi = (JsonObj,tag) => {
    return new Promise((resolve, reject) => {
        Services.postContactLead(JsonObj,tag).then((res) => {
            resolve(res);
        }).catch((err) => {
            resolve('err');
        });
    });

}
export const leadPostContactApi = (json, aWithDates) => {
    return new Promise((resolve, reject) => {
        Services.postContactLeadRegist(json, aWithDates).then((res) => {
            let responseId = res?.data?.contact?.id;


            let deleteTag = res?.data?.contact?.tags.filter(itemA => {
                let partsA = itemA.replace(/\s/g, '').toLowerCase().split(',');
                let partialMatch = aWithDates.some(itemB => {
                    let partsB = itemB.replace(/\s/g, '').toLowerCase().split(',');
                
                    return partsA.join(',') == partsB.join(',')
                   
                         });
                return partialMatch;
            });

            let json = {
                id: responseId,
                tags: res?.data?.contact?.tags,
                deleteTagss: deleteTag,
                contact : "NEW"
            }
            resolve(json);
        }).catch((err) => {
            resolve('err');
        });
    });

}

export const leadCommonApi = async (result, method,tag) => {
    if (method == "POST" && result?.contact != "NEW" && result !== "err") {
       
        const results = await leadPostApi(result,tag);
        return results
    }
}

export const GetResponse = (JsonObj, userData) => {
    return new Promise((resolve, reject) => {
       
        let aWithDates = JsonObj?.method !== "PUT" && JsonObj?.tag.map(item => `${item}`);
      
        Services.getContactLead(JsonObj.email).then((res) => {
            let responseId = res?.data?.contacts[0]?.id;
            let deleteTag = JsonObj?.method !== "PUT" && res?.data?.contacts[0]?.tags.filter(itemA => {
                let partsA = itemA.replace(/\s/g, '').toLowerCase().split(',');
                let partialMatch = JsonObj?.method !== "PUT" && aWithDates.some(itemB => {
                    let partsB = itemB.replace(/\s/g, '').toLowerCase().split(',');
                     return partsA.join(',') == partsB.join(',');
                 
                });
                return partialMatch;
            });

            let json = {
                id: responseId,
                tags: res?.data?.contacts.length > 0 && res?.data?.contacts[0]?.tags,
                deleteTagss: deleteTag,
                isContact: true
            }
            resolve(json);
           
          
        }).catch((err) => {
            resolve(err?.response?.data?.email?.message)
        })
    })
}
export const leadGetApi = async (JsonObj, json) => {
    const results = await GetResponse(JsonObj);   
    if (results == "The email address is invalid.") {
        let aWithDates = JsonObj.tag.map(item => `${item}`);
        const data = await leadPostContactApi(json, aWithDates);
        return data
    } else {
        return results
    }

}


export default Services;
