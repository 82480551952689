import React from 'react'
import { useLocation,useNavigate } from "react-router-dom";
import { useEffect } from 'react';

const Login = () => {
        let serach=useLocation().serach
        let navigate=useNavigate()
        useEffect(()=>{
            if(serach==undefined){
                navigate('/user-information/home-fitness-quiz')
            }
            // console.log("serachserach",serach)
        },[])

    return <></>
}

export default Login