import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import Services from '../network/Services';
import konsole from '../network/konsole';
import { useNavigate } from 'react-router-dom';
import { utilities, Quiz, formLabelId2 } from '../utilities/utilities';
import { Form, Tab, Row, Col } from 'react-bootstrap';
import OccuranceCom from '../components/OccuranceCom';
import { Constant } from '../utilities/Constant'
import LoaderScr from '../components/LoaderScr';
 function Health(props, ref)
{
    const [isLoading, setIsLoading] = useState(false)
    let occuranceRef = useRef(null)
    const navigate = useNavigate();
    let userDetails = utilities.getObjFromStorage('userDetails')
    let subtenantId = utilities.getSessionStoarge("subtenantId");
    let primaryUserId = utilities.getSessionStoarge("primaryUserId");
    const [formLabelData, setFormLabelData] = useState({})
    let progressBarValue = props?.progesBar
    const [questionscore2, setquestionscore2] = useState('')
    const [userId, setuserId] = useState('')
    const [formLabelData2, setFormLabelData2] = useState({})
    const [renderpage, setRenderpage] = useState(false)
    const [familymedialhistory, setfamilymedialhistory] = useState({})  
    const [threemedicalcases, setthreemedicalcases] = useState({})
    const [heartdisease, setheartdisease] = useState({})
    const [diabetes, setdiabetes] = useState({})
    const [highcolestrol, sethighcolestrol] = useState({})
    const [grocerystore, setgrocerystore] = useState({}) 
    const [highbloodpressure, sethighbloodpressure] = useState({})
    const [glucomaandvision, setglucomaandvision] = useState({})
    const [hearingloss, sethearingloss] = useState({})
    const [ambulatingbalance, setambulatingbalance] = useState({})

    const [weightmatch, setweightmatch] = useState({})
    const [exercise, setexercise] = useState({})
    const [nutition, setnutition] = useState({})
    const [sociolizing, setsociolizing] = useState({})
    const [drugs, setdrugs] = useState({})
    const [smoking, setsmoking] = useState({})
    const [drinking, setdrinking] = useState({})
    const [marriageorromace, setmarriageorromace] = useState({})


    useEffect(() => {
        let primaryUserId = utilities.getSessionStoarge("primaryUserId");
        setuserId(primaryUserId)
        getsubjectForFormLabelId(primaryUserId)
        return () => { }
      }, [])

      useImperativeHandle(ref, () => ({
        savePostData2
      }));
    

    const QuestionAndQuestion2 = (formLabelDatawithlabel, name, withlabel) => {
        
        return (
          <Col xs md="12" lg="10" className="mt-2">
            <b>{formLabelDatawithlabel && formLabelDatawithlabel.question}{" "} </b>
    
            {formLabelDatawithlabel && formLabelDatawithlabel?.response.map((response) =>
             {
              return <Form.Check  onChange={(e) => { handleRadioChange(e, formLabelDatawithlabel, response?.resScore, withlabel); }} checked={response.checked} value={response.response} label={response.response} id={response.responseId} name={name} className="chekspace mt-2" type="radio" />
            })}
          </Col>
        )
      }

      const getsubjectForFormLabelId = (userId) => {
        konsole.log("getsubjectForFormLabelId", userId)
        
    
      
    
        setIsLoading(true)
        let questionsansscore2 = 0
        let formlabelData = {};
        let dataformLabelId = formLabelId2;
        const promises = Services.getSubjectQuestions(dataformLabelId);
        promises.then((res) => {
          setIsLoading(true)
          konsole.log("getSubjectQuestions", res)
         
          let responseData = res.data.data;
          for (let item of dataformLabelId) {
            for (let i = 0; i < responseData.length; i++) {
              if (item == responseData[i]?.formLabelId) {
                let label = "label" + responseData[i]?.formLabelId;
                formlabelData[label] = responseData[i]?.question;
                setIsLoading(true)
                const promises = Services.getSubjectResponse(userId, formlabelData[label].questionId);
                promises.then((res) => {
                  konsole.log("formlabelDatares", res)
                  if (res.data.data.userSubjects.length !== 0) {
                    let responseDataa = res.data.data.userSubjects[0];
                    for (let i = 0; i < formlabelData[label].response.length; i++) {
                      setRenderpage(!setRenderpage)
                      if (formlabelData[label].response[i].responseId === responseDataa.responseId) {
                        konsole.log("formlabelData[label]", responseDataa, formlabelData[label])
                        if (responseDataa.responseNature == "Radio") {
                          formlabelData[label].response[i]["checked"] = true;
                          formlabelData[label]["userSubjectDataId"] = responseDataa.userSubjectDataId;
                          questionsansscore2 = questionsansscore2 + responseDataa?.resScore
                          setRenderpage(!setRenderpage)
                        }
                      }
                    }
                  }
                  setRenderpage(!setRenderpage)
                  setFormLabelData2(formlabelData);
                  setquestionscore2(questionsansscore2)
                  setIsLoading(false)
                }).catch((res) => {
                  konsole.log("getSubjectResponse", res)
                  setIsLoading(false)
                }).finally(() => {
                  konsole.log("getSubjectResponse")
                  setIsLoading(false)
                })
              }
              konsole.log("responseData", responseData[i])
            }
          }
        }).catch((err) => {
          konsole.log("getSubjectQuestions", err)
          setIsLoading(false)
        }).finally(() => {
          konsole.log("getSubjectQuestions")
          setIsLoading(false)
        })
      }
    



      const metaDatafunJson = (userSubjectDataId, eventValue, eventId, subjectId) => {
        return { userSubjectDataId: userSubjectDataId, subjectId: subjectId, subResponseData: eventValue, responseId: eventId }
      }

      const handleRadioChange = (event, formLabelDatawithlabel, score, formlabelwithlabel) => {
   
        event.stopPropagation()
        setquestionscore2(questionscore2 + score)
        const eventId = event.target.id;
        const eventValue = event.target.value;
        const eventName = event.target.name;
        const userSubjectDataId = (formLabelDatawithlabel?.userSubjectDataId) ? formLabelDatawithlabel?.userSubjectDataId : 0
        const subjectId = formLabelDatawithlabel?.questionId;
    
        konsole.log("eventevent", formLabelDatawithlabel, eventId)
        let formlabelData = formLabelDatawithlabel
    
        for (let [index, items] of formLabelDatawithlabel.response.entries()) {
          if (items.responseId == eventId) {
            formlabelData.response[index]['checked'] = true
          } else {
            formlabelData.response[index]['checked'] = false
          }
        }
        setFormLabelData(prevState => ({
          ...prevState,
          formlabelwithlabel: formlabelData
        }));
    
        konsole.log("formlabelwithlabelformlabelwithlabel", formlabelwithlabel)
        let returnMetaData = metaDatafunJson(userSubjectDataId, eventValue, eventId, subjectId)
        if (eventName == "familymedialhistory") { setfamilymedialhistory(returnMetaData) }
        else if (eventName == "threemedicalcases") { setthreemedicalcases(returnMetaData) }
        else if (eventName == "heartdisease") { setheartdisease(returnMetaData) }
        else if (eventName == "diabetes") { setdiabetes(returnMetaData) }
        else if (eventName == "highcolestrol") { sethighcolestrol(returnMetaData) }
        else if (eventName == "highbloodpressure") { sethighbloodpressure(returnMetaData) }
        else if (eventName == "grocerystore") { setgrocerystore(returnMetaData) }
        else if (eventName == "glucomaandvision") { setglucomaandvision(returnMetaData) }
        else if (eventName == "hearingloss") { sethearingloss(returnMetaData) }
        else if (eventName == "ambulatingbalance") { setambulatingbalance(returnMetaData) }
        
        else if (eventName == "weightmatch") { setweightmatch(returnMetaData) }
        else if (eventName == "exercise") { setexercise(returnMetaData) }
        else if (eventName == "nutition") { setnutition(returnMetaData) }
        else if (eventName == "sociolizing") { setsociolizing(returnMetaData) }
        else if (eventName == "drugs") { setdrugs(returnMetaData) }
        else if (eventName == "drinking") { setdrinking(returnMetaData) }
        else if (eventName == "marriageorromace") { setmarriageorromace(returnMetaData) }
        

        
      }
      
  //PUT API----------------------------------------------------------------------------------------------------------------
  const savePostData2 = () => {

    let userSubjects = []
    if (utilities.objectvalidation(familymedialhistory)) { userSubjects.push(familymedialhistory) }

    if (utilities.objectvalidation(threemedicalcases)) { userSubjects.push(threemedicalcases) }
    if (utilities.objectvalidation(heartdisease)) { userSubjects.push(heartdisease) }
    if (utilities.objectvalidation(diabetes)) { userSubjects.push(diabetes) }
    if (utilities.objectvalidation(highcolestrol)) { userSubjects.push(highcolestrol) }
    if (utilities.objectvalidation(highbloodpressure)) { userSubjects.push(highbloodpressure) }
    if (utilities.objectvalidation(grocerystore)) { userSubjects.push(grocerystore) }
    if (utilities.objectvalidation(glucomaandvision)) { userSubjects.push(glucomaandvision) }
    if(utilities.objectvalidation(hearingloss)){ userSubjects.push(hearingloss) }
    if (utilities.objectvalidation(ambulatingbalance)) { userSubjects.push(ambulatingbalance) }

    if (utilities.objectvalidation(weightmatch)) { userSubjects.push(weightmatch) }
    if (utilities.objectvalidation(exercise)) { userSubjects.push(exercise) }
    if (utilities.objectvalidation(nutition)) { userSubjects.push(nutition) }
    if (utilities.objectvalidation(sociolizing)) { userSubjects.push(sociolizing) }
    if (utilities.objectvalidation(drugs)) { userSubjects.push(drugs) }
    if (utilities.objectvalidation(drinking)) { userSubjects.push(drinking) }
    if (utilities.objectvalidation(smoking)) { userSubjects.push(smoking) }
    if (utilities.objectvalidation(marriageorromace)) { userSubjects.push(marriageorromace) }


    let jsonobj = { "userId": userId, "userSubjects": userSubjects }

    konsole.log("jsonobj", JSON.stringify(jsonobj), "userSubjects", userSubjects)

    setIsLoading(true)

    let promises = Services.putSubjectResponse(jsonobj)
    promises.then((res) => {
      setIsLoading(false)
     konsole.log("putSubjectResponse", res)
      getsubjectForFormLabelId(userId)
        let textvalue = (questionscore2 > 80) ? Constant.health1 : (questionscore2 >= 60 && questionscore2 <= 80) ? Constant.health2 : (questionscore2 >= 40 && questionscore2 <= 59) ? Constant.health3 : Constant.health3;
        let imagevalue = (questionscore2 > 80) ? '😀' : (questionscore2 >= 60 && questionscore2 <= 80) ? '😐' : (questionscore2 >= 40 && questionscore2 <= 59) ? '🙁' : '🙁';
        let emaillegal=Constant.emailhealth
        
        let promises=Services.getSubjectQuizScore(primaryUserId,13)
        promises.then((res)=>{
          let responseData=res.data.data
          konsole.log("getSubjectQuizScore",responseData)
        
          navigate("/user-information/fitness-quiz-result",
          
          { state: { textvalue: textvalue, imagevalue: imagevalue,emaillegal:emaillegal } });
         let questionscore22=imagevalue+" Your score is <b>"+questionscore2+"</b> points. "
          let infoobj = {
            userDetails: userDetails,
            restltText: textvalue,
            resultEmoji: imagevalue,
            questionscore2:questionscore22,
            dimension1:responseData?.dimension1,
            dimension2:responseData?.dimension2,
            dimension3:responseData?.dimension3,
            finalThoughts:responseData?.finalThoughts,
            introduction:responseData?.introduction,
            resultsOverview:responseData?.resultsOverview,
    
          }
          konsole.log("infoobjinfoobj",infoobj)
        
          occuranceRef.current.calloccuranceIdfun(25, subtenantId, infoobj)
        }).catch((err)=>{
          konsole.log("getSubjectQuizScore",err)
        }).finally(()=>{
          konsole.log("getSubjectQuizScore")
        })
      // }
    }).catch((err) => {
      setIsLoading(false)
      konsole.log("putSubjectResponse", err)
    }).finally(() => {
      setIsLoading(false)
      konsole.log("putSubjectResponse")
    })
  }



 
    return(
        <>
           <LoaderScr isLoading={isLoading} />
      <OccuranceCom ref={occuranceRef} />
      <div className="container">
        <div className="d-flex align-items-center gap-2 my-3">
          <div className=" w-100  p-2">
            <Form.Group as={Row} className="mb-3">
              {(progressBarValue == 25) ? <>
           
                <p className='fs-5'><b> Family Medical History</b></p>
                {QuestionAndQuestion2(formLabelData2?.label963, 'familymedialhistory','label963')}
              
              </> : (progressBarValue == 50) ? <>
              <p className='fs-5'><b>Personal Medical History</b></p>
                {QuestionAndQuestion2(formLabelData2?.label964, 'threemedicalcases','label964')}
                {QuestionAndQuestion2(formLabelData2?.label965, 'heartdisease','label965')}
                {QuestionAndQuestion2(formLabelData2?.label966, 'diabetes','label966')}
                {QuestionAndQuestion2(formLabelData2?.label967, 'highcolestrol','label967')}
                {QuestionAndQuestion2(formLabelData2?.label968, 'highbloodpressure','label968')}
                {QuestionAndQuestion2(formLabelData2?.label969, 'glucomaandvision','label969')}
                {QuestionAndQuestion2(formLabelData2?.label970, 'hearingloss','label970')}
                {QuestionAndQuestion2(formLabelData2?.label971, 'ambulatingbalance','label971')}
             
              </> : <>
                <p className='fs-5'><b>Lifestyle History</b></p>
                {QuestionAndQuestion2(formLabelData2?.label972, 'weightmatch','label972')}
                {QuestionAndQuestion2(formLabelData2?.label973, 'exercise','label973')}
                {QuestionAndQuestion2(formLabelData2?.label974, 'nutition','label974')}
                {QuestionAndQuestion2(formLabelData2?.label975, 'sociolizing','label975')}
                {QuestionAndQuestion2(formLabelData2?.label976, 'drugs','label976')}
                {QuestionAndQuestion2(formLabelData2?.label977, 'drinking','label977')}
                {QuestionAndQuestion2(formLabelData2?.label978, 'smoking','label978')}
                {QuestionAndQuestion2(formLabelData2?.label979, 'marriageorromace','label979')}
               

              </>}
            </Form.Group>
          </div>
        </div>
      </div>
        </>
    )
}
export default forwardRef(Health)