exports.Constant = {
    DEFAULT_SUBTENANTID_FOR_OCCURRENCE:2,
    textone:'Congratulations. Your home is most likely age-friendly.',
    texttwo:'It is not likely that you will be able to age in place without further planning and renovations. If you choose not to make changes (and you don’t create an alternative housing plan in advance), chances are good that you will create significant burdens and worries for your loved ones.',
    textthree:'You’re at risk. Make a plan to move to an age-friendly home by the age of 75. If you choose not to move. you should take significant steps to renovate your home to avoid being a burden on your loved ones.',
    textfour:'Your home is not suitable to retire in.',

    testresultone:'Congratulations! Your answers to the Home Fitness Quiz indicate that you will be able to live out your life in your own home, so long as your loved ones who live nearby will be willing and able to oversee your care needs.',

    testresulttwo:'You are at risk! Your answers to the Home Fitness Quiz indicate that it is not be able to age in your current home without further planning and renovations. If you choose not to move, you should take significant steps to renovate your home to avoid being a burden on your loved ones.',
    
    testresultthree:'You are at risk! Your answers to the Home Fitness Quiz indicate that it is highly unlikely that you will be able to age in your current home without further planning and renovations. If you choose not to move, you should take significant steps to renovate your home to avoid being a burden on your loved ones.',

    testresultfour:'You are at risk! Your answers to the Home Fitness Quiz indicate that you will not be able to age in your current home without further planning and renovations. If you choose not to move, you should take significant steps to renovate your home to avoid being a burden on your loved ones.',
    // testresultfour:'You are at risk! Your answers to the Home Fitness Quiz indicate that you may highly unlikely that you will be able to age in your current home without further planning and renovations. If you choose not to move, you should take significant steps to renovate your home to avoid being a burden on your loved ones.',

    emaillegal:" Check your email for a detailed report about your Legal Quiz results. This report suggests things you can do to increase the odds that you will be able to grow old in the home of your choosing. You’ll also find links to resources that will make the planning process easier. "  ,
    emailhealth:" Check your email for a detailed report about your Health Quiz results. This report suggests things you can do to increase the odds that you will be able to grow old in the home of your choosing. You’ll also find links to resources that will make the planning process easier. "  ,
    emailhome:" Check your email for a detailed report about your Home Fitness Quiz results. This report suggests things you can do to increase the odds that you will be able to grow old in the home of your choosing. You’ll also find links to resources that will make the planning process easier. "  ,
    
    health1:"You are doing well.",
    health2:"You will need to work somewhat harder to stay healthy.",
    health3:"The future of your health may not be very great without a lot of hard work.",
    
    
    
    legaltest1:'You might want to update your current estate plan to make sure that your fiduciaries understand your goals and needs.',
    legaltest2:"You should probably update your estate plan to have more than traditional legal documents. By updating your existing plan, you can ease the burden on your fiduciaries, clarify your expectations, and state your pre-arranged choices.",
    legaltest3:"You should definitely to update your plan since the current status of your estate plan leaves you exposed in several areas. An updated plan will close the gaps and keep your protected.",
    legaltest4:"You must update your plan since your score indicates a lack of preparedness. Consider making an appointment with an elder law attorney at your earliest convenience.",

    Dimension1:{
        label953Yes:'Your house sounds like it is ideal for aging in place; you can access all your living spaces on the same floor.',
        label954Yes:'You have a home that you can access without having to navigate stairs.',
      }

}