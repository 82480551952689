exports.utilities = {
    setSessionStoarge: function (key, value) {
        sessionStorage.setItem(key, value);
    },
    getSessionStoarge: function (value) {
        return sessionStorage.getItem(value);
    },
    getObjFromStorage: function (key) {
        return JSON.parse(sessionStorage.getItem(key));
    },
    getSubjectData: (subjectId, responseId, memberUserId, userSubjectDataId) => {
        return { userId: memberUserId, userSubjectDataId: userSubjectDataId || 0, subjectId: subjectId, responseId: responseId,}
    },
    objectvalidation:(obj)=>{
        if (Object.keys(obj).length === 0) {
            return false
          } else {
            return true
          }
    },
    formatUsPhoneNumber:(input) => {
        const phoneNumber = input.replace(/\D/g, ''); // extract only numeric characters
        const phoneNumberLength = phoneNumber.length;
          if (phoneNumberLength < 4) {
          return phoneNumber;
        } else if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        } else {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
        }
      }
}
exports.Quiz = {
    formLabelId: [,
        { id: 157, },
        { id: 158, },
        { id: 159, },
        { id: 160, },
        { id: 161, },
        { id: 162, },
        { id: 163  },
        { id: 164  },
        { id: 165  },
    ]
}
exports.formLabelId=[953,954,955,956,957,958,959,960,961,962]
exports.formLabelId2=[963,964,965,965,967,968,969,970,971,972,973,974,975,976,977,978,979]
exports.formLabelId3=[980,981,982,983,984,985,986,987,988,989,990,991]

