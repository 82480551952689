const demo = false;

export const BASE_URL = "https://aointakeapi.azurewebsites.net";
export const LeadStackerBASE_URL = "https://rest.gohighlevel.com"

const urlPath ={
    createUser: '/api/User/CreateUser',
    getSubjectQuestionsPath: "api/Subject/get-subject-for-form-label-id",
    getSubjectResponsePath: 'api/Subject/get-user-subject/',
    putSubjectResponse: 'api/Subject/update-user-subject-data',
    getUserDetailsByEmailId:'/api/Account/GetUserDetailByEmailId',
    getPostCommMediumPath:'/api/CommMedium/GetCommMedium',
    getTextTemplate: "/api/TextTemplates/GetTextTemplates",
    GetEmailTemplate: "/api/EmailTemplates/GetEmailTemplates",
    PostEmailCom: "/api/EmailComm/SendEmailComm",
    getSubjectQuizScore:'/api/Subject/get-quiz-score/',
    createAcademyUser:'/api/User/CreateAcademyUser',
    getLeadstackerContacts : "v1/contacts/lookup",
    postLeadstackerContacts : "v1/contacts/",
}

export default urlPath;