import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import Services from '../network/Services';
import konsole from '../network/konsole';
import { useNavigate } from 'react-router-dom';
import { utilities, Quiz, formLabelId } from '../utilities/utilities';
import { Form, Tab, Row, Col } from 'react-bootstrap';
import OccuranceCom from '../components/OccuranceCom';
import { Constant } from '../utilities/Constant'
import LoaderScr from '../components/LoaderScr';

const HomeStructure = (props, ref) => {
  const navigate = useNavigate();
  let occuranceRef = useRef(null)

  let progressBarValue = props?.progesBar
  const [isLoading, setIsLoading] = useState(false)
  let userDetails = utilities.getObjFromStorage('userDetails')
  let subtenantId = utilities.getSessionStoarge("subtenantId");
  let primaryUserId = utilities.getSessionStoarge("primaryUserId");

  const [userId, setuserId] = useState('')
  const [formLabelData, setFormLabelData] = useState({})

  const [stylehomeDetails, setstylehomeDetails] = useState({})
  const [stylehomeSubDetails, setstylehomeSubDetails] = useState({})
  const [gainentranceyourhome, setgainentranceyourhome] = useState({})
  const [walkinshower, setwalkinshower] = useState({})
  const [doorwaysanddoorways, setdoorwaysanddoorways] = useState({})
  const [preferredhospital, setpreferredhospital] = useState({})
  const [firedepartment, setfiredepartment] = useState({})
  const [grocerystore, setgrocerystore] = useState({})
  const [neighborhood, setneighborhood] = useState({})
  const [friendsresidence, setfriendsresidence] = useState({})
  const [familymedicalhistory,setfamilymedicalhistory] = useState({})
  const [questionscore, setquestionscore] = useState('')
  const [renderpage, setRenderpage] = useState(false)
  //predefine-----------------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    savePostData
  }));

  //-----------------------------------------------------------------------------
  useEffect(() => {
    let primaryUserId = utilities.getSessionStoarge("primaryUserId");
    setuserId(primaryUserId)
    getsubjectForFormLabelId(primaryUserId)
    return () => { }
  }, [])

  konsole.log("formLabelDataformLabelDataformLabelData", formLabelData)

 
  // -----------------------------------------------------------------------------

  const getsubjectForFormLabelId = (userId) => {
    konsole.log("getsubjectForFormLabelId", userId)
    

  

    setIsLoading(true)
    let questionsansscore = 0
    let formlabelData = {};
    let dataformLabelId = formLabelId;
    const promises = Services.getSubjectQuestions(dataformLabelId);
    promises.then((res) => {
      setIsLoading(true)
      konsole.log("getSubjectQuestions", res)
  
      let responseData = res.data.data;
      for (let item of dataformLabelId) {
        for (let i = 0; i < responseData.length; i++) {
          if (item == responseData[i]?.formLabelId) {
            let label = "label" + responseData[i]?.formLabelId;
            formlabelData[label] = responseData[i]?.question;
            setIsLoading(true)
            const promises = Services.getSubjectResponse(userId, formlabelData[label].questionId);
            promises.then((res) => {
              konsole.log("formlabelDatares", res)
              if (res.data.data.userSubjects.length !== 0) {
                let responseDataa = res.data.data.userSubjects[0];
                for (let i = 0; i < formlabelData[label].response.length; i++) {
                  setRenderpage(!setRenderpage)
                  if (formlabelData[label].response[i].responseId === responseDataa.responseId) {
                    konsole.log("formlabelData[label]", responseDataa, formlabelData[label])
                    if (responseDataa.responseNature == "Radio") {
                      formlabelData[label].response[i]["checked"] = true;
                      formlabelData[label]["userSubjectDataId"] = responseDataa.userSubjectDataId;
                      questionsansscore = questionsansscore + responseDataa?.resScore
                      setRenderpage(!setRenderpage)
                    }
                  }
                }
              }
              setRenderpage(!setRenderpage)
              setFormLabelData(formlabelData);
              setquestionscore(questionsansscore)
              setIsLoading(false)
            }).catch((res) => {
              konsole.log("getSubjectResponse", res)
              setIsLoading(false)
            }).finally(() => {
              konsole.log("getSubjectResponse")
              setIsLoading(false)
            })
          }
          konsole.log("responseData", responseData[i])
        }
      }
    }).catch((err) => {
      konsole.log("getSubjectQuestions", err)
      setIsLoading(false)
    }).finally(() => {
      konsole.log("getSubjectQuestions")
      setIsLoading(false)
    })
  }
  // -------------------------HandleChangeRadio----------------------------------------------------
  const metaDatafunJson = (userSubjectDataId, eventValue, eventId, subjectId) => {
    return { userSubjectDataId: userSubjectDataId, subjectId: subjectId, subResponseData: eventValue, responseId: eventId }
  }
  const handleRadioChange = (event, formLabelDatawithlabel, score, formlabelwithlabel) => {
    event.stopPropagation()
  
    setquestionscore(questionscore + score)
    const eventId = event.target.id;
    const eventValue = event.target.value;
    const eventName = event.target.name;
    const userSubjectDataId = (formLabelDatawithlabel?.userSubjectDataId) ? formLabelDatawithlabel?.userSubjectDataId : 0
    const subjectId = formLabelDatawithlabel?.questionId;

    konsole.log("eventevent", formLabelDatawithlabel, eventId)
    let formlabelData = formLabelDatawithlabel

    for (let [index, items] of formLabelDatawithlabel.response.entries()) {
      if (items.responseId == eventId) {
        formlabelData.response[index]['checked'] = true
      } else {
        formlabelData.response[index]['checked'] = false
      }
    }
    setFormLabelData(prevState => ({
      ...prevState,
      formlabelwithlabel: formlabelData
    }));

    konsole.log("formlabelwithlabelformlabelwithlabel", formlabelwithlabel)
    let returnMetaData = metaDatafunJson(userSubjectDataId, eventValue, eventId, subjectId)
    if (eventName == "stylehome") { setstylehomeDetails(returnMetaData) }
    else if (eventName == "gainentranceyourhome") { setgainentranceyourhome(returnMetaData) }
    else if (eventName == "walkinshower") { setwalkinshower(returnMetaData) }
    else if (eventName == "doorwaysanddoorways") { setdoorwaysanddoorways(returnMetaData) }
    else if (eventName == "preferredhospital") { setpreferredhospital(returnMetaData) }
    else if (eventName == "firedepartment") { setfiredepartment(returnMetaData) }
    else if (eventName == "grocerystore") { setgrocerystore(returnMetaData) }
    else if (eventName == "neighborhood") { setneighborhood(returnMetaData) }
    else if (eventName == "friendsresidence") { setfriendsresidence(returnMetaData) }
    else if (eventName == "familymedicalhistory") { setfamilymedicalhistory(returnMetaData) }
    else if (eventName == "stylehomeSub") { setstylehomeSubDetails(returnMetaData) }
  }

  //PUT API----------------------------------------------------------------------------------------------------------------
  const savePostData = () => {

    let userSubjects = []
    if (utilities.objectvalidation(stylehomeDetails)) { userSubjects.push(stylehomeDetails) }
    if (utilities.objectvalidation(stylehomeSubDetails)) { userSubjects.push(stylehomeSubDetails) }
    if (utilities.objectvalidation(gainentranceyourhome)) { userSubjects.push(gainentranceyourhome) }
    if (utilities.objectvalidation(walkinshower)) { userSubjects.push(walkinshower) }
    if (utilities.objectvalidation(doorwaysanddoorways)) { userSubjects.push(doorwaysanddoorways) }
    if (utilities.objectvalidation(preferredhospital)) { userSubjects.push(preferredhospital) }
    if (utilities.objectvalidation(firedepartment)) { userSubjects.push(firedepartment) }
    if (utilities.objectvalidation(grocerystore)) { userSubjects.push(grocerystore) }
    if (utilities.objectvalidation(neighborhood)) { userSubjects.push(neighborhood) }
     if(utilities.objectvalidation(familymedicalhistory)){ userSubjects.push(familymedicalhistory) }
    if (utilities.objectvalidation(friendsresidence)) { userSubjects.push(friendsresidence) }

    let jsonobj = { "userId": userId, "userSubjects": userSubjects }
    konsole.log("jsonobj", JSON.stringify(jsonobj), "userSubjects", userSubjects)
    setIsLoading(true)
    let promises = Services.putSubjectResponse(jsonobj)
    promises.then((res) => {
      setIsLoading(false)
      konsole.log("putSubjectResponse", res)
      getsubjectForFormLabelId(userId)

      // let textvalue = (questionscore >= 80) ? Constant.textone : (questionscore >= 60 && questionscore <= 79) ? Constant.texttwo : (questionscore >= 40 && questionscore <= 59) ? Constant.textthree : Constant.textfour;
      // let textvalue = (questionscore > 80) ? Constant.testresultone : (questionscore >= 60 && questionscore <= 80) ? Constant.testresulttwo : (questionscore >= 40 && questionscore <= 59) ? Constant.testresultthree : Constant.testresultfour;
      // let imagevalue = (questionscore > 80) ? '😀' : (questionscore >= 60 && questionscore <= 80) ? '😐' : (questionscore >= 40 && questionscore <= 59) ? '🙁' : '😟';
      // let infoobj = {
      //   userDetails: userDetails,
      //   restltText: textvalue,
      //   resultEmoji: imagevalue
      // }
      // occuranceRef.current.calloccuranceIdfun(23, subtenantId, infoobj)
      // alert("Submit Successfully")
      // navigate("/user-information/home-fitness-quiz-result", { state: { textvalue: textvalue, imagevalue: imagevalue } });

      let textvalue = (questionscore > 80) ? Constant.testresultone : (questionscore >= 60 && questionscore <= 80) ? Constant.testresulttwo : (questionscore >= 40 && questionscore <= 59) ? Constant.testresultthree : Constant.testresultfour;
      let imagevalue = (questionscore > 80) ? '😀' : (questionscore >= 60 && questionscore <= 80) ? '😐' : (questionscore >= 40 && questionscore <= 59) ? '🙁' : '😟';
      let emaillegal=Constant.emailhome
      let homename="home"
     
      let promises=Services.getSubjectQuizScore(primaryUserId,13)
      promises.then((res)=>{
        let responseData=res.data.data
        konsole.log("getSubjectQuizScore",responseData)
        navigate("/user-information/fitness-quiz-result",
         { state: { textvalue: textvalue, imagevalue: imagevalue,emaillegal:emaillegal,homename:homename } });
        let infoobj = {
          userDetails: userDetails,
          restltText: textvalue,
          resultEmoji: imagevalue,
          dimension1:responseData?.dimension1,
          dimension2:responseData?.dimension2,
          dimension3:responseData?.dimension3,
          finalThoughts:responseData?.finalThoughts,
          introduction:responseData?.introduction,
          resultsOverview:responseData?.resultsOverview,
  
        }
        konsole.log("infoobjinfoobj",infoobj)
        occuranceRef.current.calloccuranceIdfun(23, subtenantId, infoobj)
      }).catch((err)=>{
        konsole.log("getSubjectQuizScore",err)
      }).finally(()=>{
        konsole.log("getSubjectQuizScore")
      })
    }).catch((err) => {
      setIsLoading(false)
      konsole.log("putSubjectResponse", err)
    }).finally(() => {
      setIsLoading(false)
      konsole.log("putSubjectResponse")
    })
  }
 
  //==========================================================================
  const QuestionAndQuestion = (formLabelDatawithlabel, name, withlabel) => {

    return (
      <Col xs md="12" lg="10" className="mt-2">
        <b>{formLabelDatawithlabel && formLabelDatawithlabel.question}{" "} </b>

        {formLabelDatawithlabel && formLabelDatawithlabel?.response.map((response) =>
         {
          return <Form.Check onChange={(e) => { handleRadioChange(e, formLabelDatawithlabel, response?.resScore, withlabel); }} checked={response.checked} value={response.response} label={response.response} id={response.responseId} name={name} className="chekspace mt-2" type="radio" />
        })}
      </Col>
    )
  }
  konsole.log("formLabelDatalabel962", formLabelData?.label962)

  const returnvalue = (formLabelData) => {

    konsole.log("formLabelDataformLabelData", formLabelData)
  
    if (formLabelData !== undefined) {
      for (let item of formLabelData?.response) {
        if (item.response === 'No' && item?.checked === true) {
          return true
        }
      }
    }
  }

  return (
    <>
      <LoaderScr isLoading={isLoading} />
      <OccuranceCom ref={occuranceRef} />
      <div className="container">
        <div className="d-flex align-items-center gap-2 my-3">
          <div className=" w-100  p-2">
            <Form.Group as={Row} className="mb-3">
              {(progressBarValue == 25) ? <>
                <p className='fs-5'><b>Home Structure</b></p>
                {
                  QuestionAndQuestion(formLabelData?.label953, 'stylehome', 'label953')
                }
                {(returnvalue(formLabelData?.label953) === true) &&
                 QuestionAndQuestion(formLabelData?.label962, 'stylehomeSub', 'label962')}
                {/* {QuestionAndQuestion(formLabelData?.label962, 'stylehomeSub', 'label962')} */}
                {QuestionAndQuestion(formLabelData?.label954, 'gainentranceyourhome', 'label954')}
                {QuestionAndQuestion(formLabelData?.label955, 'walkinshower', 'label955')}
                {QuestionAndQuestion(formLabelData?.label956, 'doorwaysanddoorways', 'label956')}
              </> : (progressBarValue == 50) ? <>
                <p className='fs-5'><b>Neighborhood Qualities</b></p>
                {QuestionAndQuestion(formLabelData?.label957, 'preferredhospital', 'label957')}
                {QuestionAndQuestion(formLabelData?.label958, 'firedepartment', 'label958')}
                {QuestionAndQuestion(formLabelData?.label959, 'grocerystore', 'label959')}
                {QuestionAndQuestion(formLabelData?.label960, 'neighborhood', 'label960')}
              </> : <>
                <p className='fs-5'><b>Family/Friend Proximity</b></p>
                {QuestionAndQuestion(formLabelData?.label961, 'friendsresidence')}
                
          

              </>}
            </Form.Group>
          </div>
        </div>
      </div>
    </>
  )
}
export default forwardRef(HomeStructure) 