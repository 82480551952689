import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { utilities } from '../utilities/utilities';
import Services from '../network/Services';
import konsole from '../network/konsole';
import { leadGetApi, leadCommonApi } from '../network/Services';
import LoaderScr from '../components/LoaderScr';

function UserInformation() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [userName, setUserName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userPhone, setUserPhone] = useState('')
    const [userPhoneError, setUserPhoneError] = useState("");





    const registrationInLeadStackers = async () => {
        let tags = [`took free home assessment`]
        let parts = userName.split(" ")
        let json2 = {
            "firstName": parts[0],
            "lastName": parts.slice(1).join(" "),
            "email": userEmail,
            "phone": userPhone

        }
        const result2 = await getLoggedInUserdata(userEmail, "POST", tags, json2);

    }

    const handleClick = (e) => {
        setIsLoading(true)
        e.preventDefault();
        if (userPhoneError == undefined || userPhoneError == null || userPhoneError == "") {
            let result = Services.getUserDetailsByEmailId(userEmail)
            result.then((res) => {
                setIsLoading(false)
                konsole.log("getUserDetailsByEmailId", res)
                registrationInLeadStackers()
                let responseData = res?.data
                if (responseData.length > 0) {

                    navigateRoute(responseData[0]?.userId)
                } else {
                    setIsLoading(false)
                    createUserApi()

                }
            }).catch((err) => {
                konsole.log("getUserDetailsByEmailId", err)
                setIsLoading(false)
            })
        } else {
            setIsLoading(false)
        }

    }
    const handelSend = (e) => {
        if (e.target.value.length < 14 && e.target.value.length > 0) {
            setUserPhoneError("Please enter a  valid cell number");
            return;
        }

    }

    const createUserApi = () => {

        let splitUserName = userName.split(' ')
        let firstName = splitUserName[0]
        let lastName = splitUserName?.slice(1).join(' ')

        let jsonobj = {
            "subtenantId": 3,
            "signUpPlateform": 12,
            "user": {
                "roleId": 19,
                "firstName": firstName,
                "lastName": (lastName) ? lastName : firstName,
                "emailAddress": userEmail,
                "userName": userEmail,
                "countryCode": "+1",
                "mobileNumber": userPhone,
            }
        }
        konsole.log("jsonObject", JSON.stringify(jsonobj))
        setIsLoading(true)
        // let result = Services.createNewUser(jsonobj)
        let result = Services.createAcademyUser(jsonobj)
        result.then((res) => {
            setIsLoading(false)
            konsole.log("createNewUser", res)
            let responseData = res.data.data
            navigateRoute(responseData?.userId)
        }).catch((err) => {
            setIsLoading(false)
            konsole.log("createNewUser", err)
            if (err.response.data.data?.userId !== undefined && err.response.data.data?.userActivationMode === "ACTIVATE_USER") {
                navigateRoute(err.response.data.data.userId);
            }
        }).finally(() => {
            setIsLoading(false)
            konsole.log("createNewUser")
        })
    }

    //navigatewithprimaryuserId-------------------------------------------------------
    const navigateRoute = (userId) => {
        let jsonobj = { userEmail: userEmail, userMobile: userPhone, userName: userName }
        utilities.setSessionStoarge("userDetails", JSON.stringify(jsonobj));
        utilities.setSessionStoarge("primaryUserId", userId);
        utilities.setSessionStoarge("subtenantId", 3);

        navigate("/user-information/home-fitness-quiz/home");
    }
    const capitalization = (e) => {

        const words = e.target.value.split(' ');
        const capitalizedWords = words.map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });
        const capitalized = capitalizedWords.join(' ');

        // const capitalized = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
        setUserName(capitalized)

    }
    const mobileChange = (e) => {
        setUserPhone(utilities?.formatUsPhoneNumber(e.target.value))
        setUserPhoneError("")
    }
    const getLoggedInUserdata = async (email, method, tag, json2) => {


        let json = {
            email: email,
            tag: tag
        }
        const result = await leadGetApi(json, json2);
        if (result !== null && result !== undefined && result !== "" && !result?.tags.some((ele) => ele === "took free home assessment")) {
            const result2 = await leadCommonApi(result, method, tag)
            return result2
        }
        if (result == 'err') return;
    }




    return (
        <div className='container'>
            <LoaderScr isLoading={isLoading} />
            <Form className='' onSubmit={handleClick}>
                <header className='form-header my-5 d-flex gap-1 flex-column'>
                    <h4>Is Your Current Home Age-Friendly?</h4>
                    <h4>Take the Home Fitness Quiz and Find Out.</h4>
                </header>
                <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Name" required onChange={(e) => capitalization(e)} style={{ textTransform: "capitalize" }} />

                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail" >
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Email" required onChange={(e) => setUserEmail(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="text" placeholder="Phone" value={userPhone} onBlur={(e) => handelSend(e)} onChange={(e) => mobileChange(e)} />
                    <p className='text-danger mb-0'>{userPhoneError && <div className="mb-0">{userPhoneError}
                    </div>}</p>
                </Form.Group>
                <Button
                    variant="primary" type="submit"
                >
                    Next
                </Button>
                <footer className='text-center mt-5'>© Copyright AgingOptions. All Rights Reserved</footer>
            </Form>
        </div>
    )

}

export default UserInformation