import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import Services from '../network/Services';
import konsole from '../network/konsole';
import { useNavigate } from 'react-router-dom';
import { utilities, Quiz, formLabelId3 } from '../utilities/utilities';
import { Form, Tab, Row, Col } from 'react-bootstrap';
import OccuranceCom from '../components/OccuranceCom';
import { Constant } from '../utilities/Constant'
import LoaderScr from '../components/LoaderScr';
function Legal(props, ref)
{
    const [isLoading, setIsLoading] = useState(false)
    let occuranceRef = useRef(null)
    const navigate = useNavigate();
    let userDetails = utilities.getObjFromStorage('userDetails')
    let subtenantId = utilities.getSessionStoarge("subtenantId");
    let primaryUserId = utilities.getSessionStoarge("primaryUserId");
    const [formLabelData, setFormLabelData] = useState({})
    let progressBarValue = props?.progesBar
    const [questionscore2, setquestionscore2] = useState('')
    const [userId, setuserId] = useState('')
    const [formLabelData3, setformLabelData3] = useState({})
    const [renderpage, setRenderpage] = useState(false)
    
    const [overage, setoverage] = useState({})  
    const [marriedorsimplerelation, setmarriedorsimplerelation] = useState({})  
    const [needassistence, setneedassistence] = useState({})  
    const [anychildren, setanychildren] = useState({})  
    const [disabledone, setdisabledone] = useState({})  
    const [estatvalue, setestatvalue] = useState({})  
    const [trustortill, settrustortill] = useState({})  
    const [costworry, setcostworry] = useState({})  
    const [carinsurence, setcarinsurence] = useState({})  
    const [protectasset, setprotectasset] = useState({})  
    const [confidentfiuceries, setconfidentfiuceries] = useState({})  
    const [helpfiduceary, sethelpfiduceary] = useState({})  
     
  
 


    useEffect(() => {
        let primaryUserId = utilities.getSessionStoarge("primaryUserId");
        setuserId(primaryUserId)
        getsubjectForFormLabelId(primaryUserId)
        return () => { }
      }, [])

      useImperativeHandle(ref, () => ({
        savePostData3
      }));
    

    const QuestionAndQuestion3 = (formLabelDatawithlabel, name, withlabel) => {
       
        return (
          <Col xs md="12" lg="10" className="mt-2">
            <b>{formLabelDatawithlabel && formLabelDatawithlabel.question}{" "} </b>
    
            {formLabelDatawithlabel && formLabelDatawithlabel?.response.map((response) =>
             {
              return <Form.Check  onChange={(e) => { handleRadioChange(e, formLabelDatawithlabel, response?.resScore, withlabel); }} checked={response.checked} value={response.response} label={response.response} id={response.responseId} name={name} className="chekspace mt-2" type="radio" />
            })}
          </Col>
        )
      }

      const getsubjectForFormLabelId = (userId) => {
        konsole.log("getsubjectForFormLabelId", userId)
        
    
      
    
        setIsLoading(true)
        let questionsansscore2 = 0
        let formlabelData = {};
        let dataformLabelId = formLabelId3;
        const promises = Services.getSubjectQuestions(dataformLabelId);
        promises.then((res) => {
          setIsLoading(true)
          konsole.log("getSubjectQuestions", res)
         
          let responseData = res.data.data;
          for (let item of dataformLabelId) {
            for (let i = 0; i < responseData.length; i++) {
              if (item == responseData[i]?.formLabelId) {
                let label = "label" + responseData[i]?.formLabelId;
                formlabelData[label] = responseData[i]?.question;
                setIsLoading(true)
                const promises = Services.getSubjectResponse(userId, formlabelData[label].questionId);
                promises.then((res) => {
                  konsole.log("formlabelDatares", res)
                  if (res.data.data.userSubjects.length !== 0) {
                    let responseDataa = res.data.data.userSubjects[0];
                    for (let i = 0; i < formlabelData[label].response.length; i++) {
                      setRenderpage(!setRenderpage)
                      if (formlabelData[label].response[i].responseId === responseDataa.responseId) {
                        konsole.log("formlabelData[label]", responseDataa, formlabelData[label])
                        if (responseDataa.responseNature == "Radio") {
                          formlabelData[label].response[i]["checked"] = true;
                          formlabelData[label]["userSubjectDataId"] = responseDataa.userSubjectDataId;
                          questionsansscore2 = questionsansscore2 + responseDataa?.resScore
                          setRenderpage(!setRenderpage)
                        }
                      }
                    }
                  }
                  setRenderpage(!setRenderpage)
                  setformLabelData3(formlabelData);
                  setquestionscore2(questionsansscore2)
                  setIsLoading(false)
                }).catch((res) => {
                  konsole.log("getSubjectResponse", res)
                  setIsLoading(false)
                }).finally(() => {
                  konsole.log("getSubjectResponse")
                  setIsLoading(false)
                })
              }
              konsole.log("responseData", responseData[i])
            }
          }
        }).catch((err) => {
          konsole.log("getSubjectQuestions", err)
          setIsLoading(false)
        }).finally(() => {
          konsole.log("getSubjectQuestions")
          setIsLoading(false)
        })
      }
    



      const metaDatafunJson = (userSubjectDataId, eventValue, eventId, subjectId) => {
        return { userSubjectDataId: userSubjectDataId, subjectId: subjectId, subResponseData: eventValue, responseId: eventId }
      }

      const handleRadioChange = (event, formLabelDatawithlabel, score, formlabelwithlabel) => {
      
        event.stopPropagation()
        setquestionscore2(questionscore2 + score)
        const eventId = event.target.id;
        const eventValue = event.target.value;
        const eventName = event.target.name;
        const userSubjectDataId = (formLabelDatawithlabel?.userSubjectDataId) ? formLabelDatawithlabel?.userSubjectDataId : 0
        const subjectId = formLabelDatawithlabel?.questionId;
    
        konsole.log("eventevent", formLabelDatawithlabel, eventId)
        let formlabelData = formLabelDatawithlabel
    
        for (let [index, items] of formLabelDatawithlabel.response.entries()) {
          if (items.responseId == eventId) {
            formlabelData.response[index]['checked'] = true
          } else {
            formlabelData.response[index]['checked'] = false
          }
        }
        setFormLabelData(prevState => ({
          ...prevState,
          formlabelwithlabel: formlabelData
        }));
    
        konsole.log("formlabelwithlabelformlabelwithlabel", formlabelwithlabel)
        let returnMetaData = metaDatafunJson(userSubjectDataId, eventValue, eventId, subjectId)
        if (eventName == "overage") { setoverage(returnMetaData) }
        else if (eventName == "marriedorsimplerelation") { setmarriedorsimplerelation(returnMetaData) }
        else if (eventName == "needassistence") { setneedassistence(returnMetaData) }
        else if (eventName == "anychildren") { setanychildren(returnMetaData) }
        else if (eventName == "disabledone") { setdisabledone(returnMetaData) }
        else if (eventName == "estatvalue") { setestatvalue(returnMetaData) }
        else if (eventName == "trustortill") { settrustortill(returnMetaData) }
        else if (eventName == "costworry") { setcostworry(returnMetaData) }
        else if (eventName == "carinsurence") { setcarinsurence(returnMetaData) }
        else if (eventName == "protectasset") { setprotectasset(returnMetaData) }
        else if (eventName == "confidentfiuceries") { setconfidentfiuceries(returnMetaData) }
        else if (eventName == "helpfiduceary") { sethelpfiduceary(returnMetaData) }      
      }
      
  //PUT API----------------------------------------------------------------------------------------------------------------
  const savePostData3 = () => {

    let userSubjects = []
    if (utilities.objectvalidation(overage)) { userSubjects.push(overage) }
    if (utilities.objectvalidation(marriedorsimplerelation)) { userSubjects.push(marriedorsimplerelation) }
    if (utilities.objectvalidation(needassistence)) { userSubjects.push(needassistence) }
    if (utilities.objectvalidation(anychildren)) { userSubjects.push(anychildren) }
    if (utilities.objectvalidation(disabledone)) { userSubjects.push(disabledone) }
    if (utilities.objectvalidation(marriedorsimplerelation)) { userSubjects.push(marriedorsimplerelation) }
    if (utilities.objectvalidation(estatvalue)) { userSubjects.push(estatvalue) }
    if (utilities.objectvalidation(trustortill)) { userSubjects.push(trustortill) }
    if (utilities.objectvalidation(costworry)) { userSubjects.push(costworry) }
    if (utilities.objectvalidation(carinsurence)) { userSubjects.push(carinsurence) }
    if (utilities.objectvalidation(protectasset)) { userSubjects.push(protectasset) }
    if (utilities.objectvalidation(confidentfiuceries)) { userSubjects.push(confidentfiuceries) }
    if (utilities.objectvalidation(helpfiduceary)) { userSubjects.push(helpfiduceary) }

    let jsonobj = { "userId": userId, "userSubjects": userSubjects }
    konsole.log("jsonobj", JSON.stringify(jsonobj), "userSubjects", userSubjects)
    setIsLoading(true)
    let promises = Services.putSubjectResponse(jsonobj)
    promises.then((res) => {
      setIsLoading(false)
      konsole.log("putSubjectResponse", res)
      getsubjectForFormLabelId(userId)
      let textvalue = (questionscore2 > 15) ? Constant.legaltest4 : (questionscore2 >= 11 && questionscore2 <= 14) ? Constant.legaltest3 : (questionscore2 >= 6 && questionscore2 <= 10) ? Constant.legaltest2 : Constant.legaltest1;
      let imagevalue = (questionscore2 > 15) ? '😀' : (questionscore2 >= 11 && questionscore2 <= 14) ? '😐' : (questionscore2 >= 6 && questionscore2 <= 10) ? '🙁' : '😟';
      let emaillegal=Constant.emaillegal
     
      
      let promises=Services.getSubjectQuizScore(primaryUserId,13)
      promises.then((res)=>{
        let responseData=res.data.data
        konsole.log("getSubjectQuizScore",responseData)
        
        navigate("/user-information/fitness-quiz-result",
         { state: { textvalue: textvalue, imagevalue: imagevalue,emaillegal:emaillegal } });
  
         let questionscore22=imagevalue+" Your score is <b>"+questionscore2+"</b> points. "
        let infoobj = {
          userDetails: userDetails,
          restltText: textvalue,
          resultEmoji: imagevalue,
          questionscore2:questionscore22,
          dimension1:responseData?.dimension1,
          dimension2:responseData?.dimension2,
          dimension3:responseData?.dimension3,
          finalThoughts:responseData?.finalThoughts,
          introduction:responseData?.introduction,
          resultsOverview:responseData?.resultsOverview,
  
        }
        konsole.log("infoobjinfoobj",infoobj)
        occuranceRef.current.calloccuranceIdfun(26, subtenantId, infoobj)
      }).catch((err)=>{
        konsole.log("getSubjectQuizScore",err)
      }).finally(()=>{
        konsole.log("getSubjectQuizScore")
      })
    }).catch((err) => {
      setIsLoading(false)
      konsole.log("putSubjectResponse", err)
    }).finally(() => {
      setIsLoading(false)
      konsole.log("putSubjectResponse")
    })
  }
 
  
    return(
        <>
           <LoaderScr isLoading={isLoading} />
      <OccuranceCom ref={occuranceRef} />
      <div className="container">
        <div className="d-flex align-items-center gap-2 my-3">
          <div className=" w-100  p-2">
            <Form.Group as={Row} className="mb-3">
              {(progressBarValue == 0) ? 
              <>
                <p className='fs-5'><b>Legal</b></p>
                {QuestionAndQuestion3(formLabelData3?.label980, 'overage','label980')}
                {QuestionAndQuestion3(formLabelData3?.label981, 'marriedorsimplerelation','label981')}
                {QuestionAndQuestion3(formLabelData3?.label982, 'needassistence','label982')}
                {QuestionAndQuestion3(formLabelData3?.label983, 'anychildren','label983')}
                {QuestionAndQuestion3(formLabelData3?.label984, 'disabledone','label984')}
                {QuestionAndQuestion3(formLabelData3?.label985, 'estatvalue','label985')}
                {QuestionAndQuestion3(formLabelData3?.label986, 'trustortill','label986')}
                {QuestionAndQuestion3(formLabelData3?.label987, 'costworry','label987')}
                {QuestionAndQuestion3(formLabelData3?.label988, 'carinsurence','label988')}
                {QuestionAndQuestion3(formLabelData3?.label989, 'protectasset','label989')}
                {QuestionAndQuestion3(formLabelData3?.label990, 'confidentfiuceries','label990')}
                {QuestionAndQuestion3(formLabelData3?.label991, 'helpfiduceary','label991')}
                
              
        
             

              </>
              :<>

              </>}
            </Form.Group>
          </div>
        </div>
      </div>
        </>
    )
}
export default forwardRef(Legal)