import React, { useImperativeHandle, forwardRef } from 'react';
import Services from '../network/Services';
import konsole from '../network/konsole';
import { Constant } from '../utilities/Constant'
import { utilities } from '../utilities/utilities'

const OccuranceCom = (props, ref) => {
    let occurrenceId = 331;
    let primaryUserId = utilities.getSessionStoarge("primaryUserId");
    let subtenantId = utilities.getSessionStoarge("subtenantId");
    let info = { "emailId": "nitinnk@mailinator.com" }



    useImperativeHandle(ref, () => ({
        calloccuranceIdfun
    }));

    const calloccuranceIdfun = (occurrenceId, subtenantId, info) => {
        konsole.log("calloccuranceIdfuncalloccuranceIdfun")
        let jsonobj = { "occurrenceId": occurrenceId, "isActive": true, "subtenantId": subtenantId }

        let promises = Services.getCommMediumPath(jsonobj)
        promises.then((res) => {
            konsole.log("getCommMediumPath", res)
            let { commChannelId, applicableEmailTempId, applicableTextTempId, subtenantName } = res?.data?.data[0];
            if (commChannelId == 1) { callEmailApiFunc(applicableEmailTempId, info, subtenantName) }
        }).catch((err) => {
            konsole.log("getCommMediumPath", err)
            if (err.response.status == 404) {
                let subtenantId = Constant.DEFAULT_SUBTENANTID_FOR_OCCURRENCE;
                calloccuranceIdfun(occurrenceId, subtenantId, info)
            }
        }).finally(() => {
            konsole.log("getCommMediumPath")
        })
    }
    //----------------------------------------------------------------------------------------------------

    //-EMAIL---------------------------------------------------------------------------------------------------

    const callEmailApiFunc = (TempalteId, info, subtenantName) => {
        konsole.log("callEmailApiFunc", TempalteId)
        let isActive = true;
        let result = Services.getEmailTemplate(TempalteId, isActive)
        result.then((res) => {
            konsole.log("getEmailTemplate", res)
          
            let responseData = res.data.data[0]
            konsole.log("responseData", responseData)
            sendEmailAPIFunc(responseData, info, subtenantName)
        }).catch((err) => {
            konsole.log("getEmailTemplate", err)
        }).finally(() => {
            konsole.log("getEmailTemplatefinally")
        })
    }

 

    //-Template---------------------------------------------------------------------------------------------------

    const templateReplacer = (templateData, info, subtenantName, type) => {
        let updatedTemplateData = templateData;
        konsole.log("infoinfoinfo",info)
       
       
        
        let username=info?.userDetails?.userName
        let capitalize1 = username.toUpperCase().slice(0,1) + username.slice(1)
        
        updatedTemplateData = updatedTemplateData?.replace('@@RESULT_MESSAGE',info?.restltText).replace('@@SURVEYRESULTS',info?.questionscore2).replace('@@SUBTENANTNAME', subtenantName).replace('@@EMOJI', info?.resultEmoji)
        updatedTemplateData = updatedTemplateData?.replace('@@FINALTHOUGHTS', info?.finalThoughts).replace('@@DIMENSION1',info?.dimension1).replace('@@DIMENSION2',info?.dimension2).replace('@@DIMENSION3',info?.dimension3).replace('@@INTRODUCTION',info?.introduction) .replace('@@RESULTSOVERVIEW',info?.restltText) .replace('@@USERNAME',capitalize1 )
        return updatedTemplateData
    }

    const sendEmailAPIFunc = (responseData, info, subtenantName) => {
        let templateData = templateReplacer(responseData?.templateContent, 
            info, subtenantName, 'EMAIL')

        let jsonobj = {
            "emailType": responseData?.templateType,
            "emailTo": info?.userDetails?.userEmail,
            "emailSubject": responseData?.emailSubject,
            "emailContent": templateData,
            "emailTemplateId": responseData?.templateId,
            "emailStatusId": 1,
            "emailMappingTable": "tblUserLinks",
            // "emailMappingTablePKId": allData.clientData.agentId,
            "createdBy": primaryUserId
        }
        
        konsole.log("jsonobjjsonobj", JSON.stringify(jsonobj))
        let result = Services.PostEmailCom(jsonobj)
        result.then((res) => {
            konsole.log("PostEmailCom", res)
            
        }).catch((err) => {
            konsole.log("PostEmailCom", err)
        }).finally(() => {
            konsole.log("PostEmailCom")
        })
    }
    
    return<></>
}

 export default forwardRef(OccuranceCom)